import React from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { BsFillXCircleFill, BsCheckCircleFill } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
	Badge,
	Button,
	Input,
	Item,
	Modal,
	Page,
	Select,
	Table,
	Time,
} from '../../components';
import { useAxios } from '../../hooks';

const Announcements = () => {
	const [showEdit, setShowEdit] = React.useState(false);
	const [currentImage, setCurrentImage] = React.useState([]);
	const [params, setParams] = useSearchParams();
	const {
		register: registerSaveAnnouncement,
		setValue: setValueSaveAnnouncement,
		handleSubmit: handleSubmitSaveAnnouncement,
		reset: resetSaveAnnouncement,
		formState: { errors: errorsSaveAnnouncement },
	} = useForm();

	const api = {
		getAnnouncements: useAxios(),
		saveAnnouncement: useAxios(),
		removeAnnouncement: useAxios(),
	};

	/* getAnnouncements */

	const getAnnouncements = () => {
		api.getAnnouncements.fetch({
			method: 'GET',
			url: '/announcement/admin/findAll?page=0&size=9999',
		});
	};

	React.useEffect(() => {
		if (api.getAnnouncements.response) {
			console.log(api.getAnnouncements.response);
		}
	}, [api.getAnnouncements.response]);

	React.useEffect(() => {
		if (api.getAnnouncements.error) {
			toast.error(api.getAnnouncements.error.message);
		}
	}, [api.getAnnouncements.error]);

	React.useEffect(() => {
		getAnnouncements();
	}, []);

	/* saveAnnouncement */

	const saveAnnouncement = async (data) => {
		console.log('data', data);

		const formData = new FormData();

		formData.append(
			'saveAnnouncementDTO',
			JSON.stringify({
				...data,
				active: !!(data.active === 'true' || data.active === true),
				newTab: !!(data.newTab === 'true' || data.newTab === true),
				selectedDays: [
					'Monday',
					'Tuesday',
					'Wednesday',
					'Thursday',
					'Friday',
					'Saturday',
					'Sunday',
				],
				dateBegin:
					data.dateBegin !== ''
						? moment.utc(data.dateBegin).format()
						: '',
				dateEnd:
					data.dateEnd !== ''
						? moment.utc(data.dateEnd).format()
						: '',
				...(currentImage.length &&
				currentImage[0] &&
				typeof currentImage[0] === 'string'
					? { contents: [currentImage[0]] }
					: null),
			})
		);

		if (
			currentImage.length &&
			currentImage[0] &&
			currentImage[0] instanceof File
		) {
			formData.append('icon', currentImage[0]);
		}

		api.saveAnnouncement.fetch({
			method: 'POST',
			url: '/announcement/admin/save',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	};

	React.useEffect(() => {
		if (api.saveAnnouncement.response) {
			resetSaveAnnouncement();
			setCurrentImage([]);
			setShowEdit(false);
			toast.success('Kaydedildi.');
			getAnnouncements();
		}
	}, [api.saveAnnouncement.response]);

	React.useEffect(() => {
		if (api.saveAnnouncement.error) {
			toast.error(api.saveAnnouncement.error.message);
		}
	}, [api.saveAnnouncement.error]);

	const removeImage = (position) => {
		const images = [...currentImage];
		images.splice(position, 1);
		setCurrentImage(images);
	};

	React.useEffect(() => {
		if (params.get('edit') !== null) {
			setParams({});
			setShowEdit(true);
		}
	}, [params.get('edit')]);

	React.useEffect(() => {
		if (showEdit.id && api.getAnnouncements.response) {
			const { id } = showEdit;
			const item =
				api.getAnnouncements.response.data.content[
					api.getAnnouncements.response.data.content.findIndex(
						(object) => object.id === id
					)
				];
			const values = [
				{ name: item.name },
				{ title: item.title },
				{ url: item.url },
				{ videoUrl: item.videoUrl },
				{ newTab: item.newTab },
				{ type: item.type },
				{ imageOrder: item.imageOrder },

				{
					dateBegin: moment(item.dateBegin).format(
						'YYYY-MM-DD HH:mm:ss'
					),
				},
				{ dateEnd: moment(item.dateEnd).format('YYYY-MM-DD HH:mm:ss') },
				{ active: item.active },
			];

			values.forEach((value) => {
				setValueSaveAnnouncement(
					Object.keys(value)[0],
					Object.values(value)[0],
					{ shouldValidate: true }
				);
			});

			if (
				item.announcementContentList &&
				Array.isArray(item.announcementContentList)
			) {
				const images = [];
				item.announcementContentList.forEach((image) => {
					images.push(image);
				});
				setCurrentImage(images);
			}
		}
	}, [showEdit.id && api.getAnnouncements.response]);

	/* removeAnnouncement */

	const removeAnnouncement = (id) => {
		api.removeAnnouncement.fetch({
			method: 'POST',
			url: '/announcement/admin/delete/',
			data: id,
		});
	};

	React.useEffect(() => {
		if (api.removeAnnouncement.response) {
			setShowEdit(false);
			toast.success('Silindi.');
			getAnnouncements();
		}
	}, [api.removeAnnouncement.response]);

	React.useEffect(() => {
		if (api.removeAnnouncement.error) {
			toast.error(api.removeAnnouncement.error.message);
		}
	}, [api.removeAnnouncement.error]);

	/* return */

	return (
		<Page>
			<Page.Header header="Duyurular" title="Tüm Duyurular">
				<Page.Header.Add show onClick={() => setShowEdit(true)} />
			</Page.Header>
			<Page.Body
				loading={api.getAnnouncements.loading}
				show={
					!api.getAnnouncements.loading &&
					api.getAnnouncements.response
				}
			>
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'Duyuru',
								'URL',
								'Yeni Sekme',
								'Tarih',
								'Durum',
								'',
							]}
						/>
						<Table.Body>
							{api.getAnnouncements.response?.data?.content
								?.length &&
								api.getAnnouncements.response.data.content.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												<Item
													image={
														item.announcementContentList &&
														item
															.announcementContentList[0]
															? item
																.announcementContentList[0]
															: require('../../assets/logo-connection.png')
													}
													title={item.name}
													className="w-96 [&_img]:object-contain [&_img]:p-2 [&_img]:bg-white"
												/>
											</Table.Column>
											<Table.Column>
												<div className="flex items-center gap-1 truncate whitespace-nowrap text-xs">
													{item.url}
												</div>
											</Table.Column>
											<Table.Column>
												{item.newTab ? (
													<BsCheckCircleFill className="h-4 w-4 text-green-500" />
												) : (
													<BsFillXCircleFill className="h-4 w-4 text-red-500" />
												)}
											</Table.Column>
											<Table.Column>
												<Time
													dateBegin={moment(
														item.dateBegin
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.dateBegin
													).format('HH:mm:ss')}
													dateEnd={moment(
														item.dateEnd
													).format('DD.MM.YYYY')}
													timeEnd={moment(
														item.dateEnd
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											<Table.Column>
												{item.active ? (
													<Badge variant="success">
														<div
															className="aspect-square w-1.5 animate-pulse
													rounded-full bg-green-500"
														/>
														Aktif
													</Badge>
												) : (
													<Badge variant="danger">
														<div className="aspect-square w-1.5 rounded-full bg-red-500" />
														Pasif
													</Badge>
												)}
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<Button
													size="xs"
													variant="secondary-outline"
													className="inline-flex"
													onClick={() => {
														setShowEdit({
															id: item.id,
														});
													}}
												>
													Düzenle
												</Button>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={Boolean(showEdit)}
				loading={
					api.getAnnouncements.loading ||
					api.saveAnnouncement.loading ||
					api.removeAnnouncement.loading
				}
				size="lg"
				title={!showEdit.id ? 'Yeni Ekle' : 'Düzenle'}
				onClose={() => {
					setShowEdit(false);
					setCurrentImage([]);
					resetSaveAnnouncement();
				}}
			>
				<form
					onSubmit={handleSubmitSaveAnnouncement(saveAnnouncement)}
					noValidate
				>
					<div className="px-6">
						<Modal.Section title="İçerik">
							<Select
								name="type"
								label="Tür"
								grid
								options={[
									{
										name: 'Anasayfa',
										value: 'homepage',
									},
									{
										name: 'Slider',
										value: 'slider',
									}
								]}
								register={registerSaveAnnouncement}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveAnnouncement}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
							<Input
								type="text"
								name="name"
								label="İsim"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveAnnouncement}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveAnnouncement}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="title"
								label="Başlık"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveAnnouncement}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveAnnouncement}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="text"
								name="url"
								label="Bağlantı"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveAnnouncement}
								errors={errorsSaveAnnouncement}
								defaultValue="/"
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Select
								name="newTab"
								label="Yeni Sekmede Aç"
								grid
								options={[
									{
										name: 'Evet',
										value: true,
									},
									{
										name: 'Hayır',
										value: false,
									},
								]}
								register={registerSaveAnnouncement}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveAnnouncement}
								size="lg"
								className={{ select: '!text-sm' }}
							/>

							<Input
								type="datetime-local"
								name="dateBegin"
								label="Başlangıç Tarihi"
								grid
								placeholder="Lütfen giriniz."
								defaultValue={moment(Date.now()).format(
									'YYYY-MM-DD HH:mm'
								)}
								register={registerSaveAnnouncement}
								errors={errorsSaveAnnouncement}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Input
								type="datetime-local"
								name="dateEnd"
								label="Bitiş Tarihi"
								grid
								placeholder="Lütfen giriniz."
								defaultValue={moment(Date.now())
									.add(1, 'years')
									.format('YYYY-MM-DD HH:mm')}
								register={registerSaveAnnouncement}
								errors={errorsSaveAnnouncement}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
							<Select
								name="active"
								label="Durum"
								grid
								options={[
									{
										name: 'Aktif',
										value: true,
									},
									{
										name: 'Pasif',
										value: false,
									},
								]}
								defaultValue
								register={registerSaveAnnouncement}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								errors={errorsSaveAnnouncement}
								size="lg"
								className={{ select: '!text-sm' }}
							/>
							<Input
								type="number"
								name="imageOrder"
								label="Sıra"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveAnnouncement}
								validation={{
									required: 'Bu alan zorunludur.',
								}}
								defaultValue={0}
								errors={registerSaveAnnouncement}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>
						<Modal.Section title="Video">
							<Input
								type="text"
								name="videoUrl"
								label="Video Adresi"
								grid
								placeholder="Lütfen giriniz."
								register={registerSaveAnnouncement}
								errors={errorsSaveAnnouncement}
								size="lg"
								className={{ input: '!text-sm' }}
							/>
						</Modal.Section>

						<Modal.Section title="Görsel">
							{/* images start */}
							<div className="group">
								<div className="grid grid-cols-3 items-center">
									<label className="col-span-1 flex gap-1 text-sm font-normal text-slate-700">
										Görsel
									</label>
									<div className="col-span-2">
										<div className="mb-4 grid grid-cols-4 gap-4">
											{currentImage &&
											currentImage.length ? (
													currentImage.map(
														(image, index) => (
															<div
																className="relative inline-block"
																key={index}
															>
																<img
																	src={
																		typeof image ===
																	'object'
																			? window.URL.createObjectURL(
																				image
																			)
																			: image
																	}
																	key={index}
																	alt=""
																	className="aspect-square h-full w-full
															rounded-lg object-cover shadow bg-slate-50"
																/>
																<button
																	type="button"
																	className="absolute right-0 top-0 -translate-y-2 translate-x-2"
																	onClick={() =>
																		removeImage(
																			index
																		)
																	}
																>
																	<BsFillXCircleFill
																		className="translate h-5 w-5
															rounded-full border-2 bg-white border-white text-red-500"
																	/>
																</button>
															</div>
														)
													)
												) : (
													<div
														className="col-span-4 rounded-md p-3 text-center
											text-xs text-slate-400 bg-slate-50"
													>
													Görsel Bulunamadı
													</div>
												)}
										</div>
										<div className="relative">
											<input
												type="file"
												accept="image/*"
												onChange={(event) => {
													if (
														event?.target
															?.files?.[0]
													) {
														setCurrentImage([
															...currentImage,
															event.target
																.files[0],
														]);
													}
												}}
												className="absolute left-0 top-0 h-full w-full cursor-pointer opacity-0"
											/>
											{!currentImage.length && (
												<div
													className={`flex h-9 w-full items-center justify-center whitespace-nowrap
                                                        rounded-lg border-2 px-3.5 text-center text-sm font-normal leading-none
                                                        transition
                                                        duration-300 border-transparent text-white bg-blue-500 hover:bg-blue-600
                                                    `}
												>
													Yeni Görsel Ekle
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							{/* images end */}
						</Modal.Section>
					</div>

					{showEdit.id && (
						<Input
							type="hidden"
							name="id"
							register={registerSaveAnnouncement}
							defaultValue={showEdit.id}
						/>
					)}

					<div className="flex items-center justify-center gap-3 border-t-2 px-4 py-3 border-slate-100">
						{showEdit.id && (
							<Button
								type="button"
								variant="danger-outline"
								size="md"
								className="w-32"
								onClick={() => {
									removeAnnouncement(showEdit.id);
								}}
							>
								Sil
							</Button>
						)}
						<Button
							type="submit"
							variant="primary"
							size="md"
							disabled={
								Object.keys(errorsSaveAnnouncement).length > 0
							}
							className="w-32"
						>
							Kaydet
						</Button>
					</div>
				</form>
			</Modal>
		</Page>
	);
};

export default Announcements;
