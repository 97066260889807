/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { act } from 'react';
import toast from 'react-hot-toast';
import moment from 'moment';
import { useAxios } from '../../hooks';
import { Badge, Button, Page, Table, Time, Modal } from '../../components';

const EmployeePhotos = () => {
	const [activeItem, setActiveItem] = React.useState(false);
	const [showWaybill, setShowWaybill] = React.useState(false);
	const [dashboardItems, setDashboardItems] = React.useState();

	const api = {
		getEmployeePhotos: useAxios(),
		saveEmployeePhotoStatus: useAxios(),
	};

	const getEmployeePhotos = () => {
		api.getEmployeePhotos.fetch({
			method: 'GET',
			url: '/employee/get/employee/images/except/approved',
		});
	};

	const saveEmployeePhotoStatus = (data) => {
		api.saveEmployeePhotoStatus.fetch({
			method: 'POST',
			url: '/employee/set/employee/image/status',
			data,
		});
	};

	const saveWaybill = (data) => {
		console.log('data: ', data);
	};

	React.useEffect(() => {
		getEmployeePhotos();
	}, []);

	React.useEffect(() => {
		if (api.getEmployeePhotos.response) {
			setDashboardItems([
				{
					id: '1',
					name: 'Toplam',
					value: 0,
				},
			]);
		}
	}, [api.getEmployeePhotos.response]);

	React.useEffect(() => {
		if (api.getEmployeePhotos.error) {
			toast.error(api.getEmployeePhotos.error.message);
		}
	}, [api.getEmployeePhotos.error]);

	React.useEffect(() => {
		if (api.saveEmployeePhotoStatus.response) {
			toast.success('Kaydedildi.');
			getEmployeePhotos();
		}
	}, [api.saveEmployeePhotoStatus.response]);

	React.useEffect(() => {
		if (api.saveEmployeePhotoStatus.error) {
			toast.error(api.saveEmployeePhotoStatus.error.message);
		}
	}, [api.saveEmployeePhotoStatus.error]);

	return (
		<Page>
			<Page.Header header="Üye İşlemleri" title="Fotoğraf Onayları" />

			<Page.Body
				loading={
					api.getEmployeePhotos.loading ||
					api.saveEmployeePhotoStatus.loading
				}
				show={
					api.getEmployeePhotos.response &&
					api.getEmployeePhotos.loading === false &&
					api.saveEmployeePhotoStatus.loading === false
				}
			>
				<Page.Dashboard items={dashboardItems} />
				<Page.Content>
					<Table>
						<Table.Head
							data={[
								'Id',
								'Fotoğraf',
								'Tür',
								'Tarih',
								'Durum',
								'',
							]}
						/>
						<Table.Body>
							{api.getEmployeePhotos.response?.data?.content
								?.length &&
								api.getEmployeePhotos.response.data.content.map(
									(item, index) => (
										<Table.Row key={index}>
											<Table.Column>
												<span className="whitespace-nowrap text-xs font-normal text-slate-400">
													{item.id}
												</span>
											</Table.Column>
											<Table.Column>
												{item.employeeImageContentType === 'Irsaliye' ?
													<button
														type="button"
														onClick={() => {
															setActiveItem(item);
															setShowWaybill(true);
														}}
													>
														<img
															src={item.imageUrl}
															alt=""
															className="aspect-square w-20 rounded-lg object-cover"
														/>
													</button>
												: <img
														src={item.imageUrl}
														alt=""
														className="aspect-square w-20 rounded-lg object-cover"
												/>
												}
											</Table.Column>
											<Table.Column>
												<Badge>{item.employeeImageContentType}</Badge>
											</Table.Column>
											<Table.Column>
												<Time
													dateBegin={moment(
														item.dateUpload
													).format('DD.MM.YYYY')}
													timeBegin={moment(
														item.dateUpload
													).format('HH:mm:ss')}
												/>
											</Table.Column>
											<Table.Column>
												<Badge
													variant={
														{
															ONAYLANDI:
																'success',
															REDDEDİLDİ:
																'danger',
															'ONAY BEKLİYOR':
																'warning',
														}[item.imageStatus]
													}
												>
													{
														{
															ONAYLANDI:
																'Onaylandı',
															REDDEDİLDİ:
																'Reddedildi',
															'ONAY BEKLİYOR':
																'Onay Bekliyor',
														}[item.imageStatus]
													}
												</Badge>
											</Table.Column>
											<Table.Column className="text-right [&_button]:w-20">
												<div className="flex flex-col items-end justify-center gap-2 ">
													{item.imageStatus !==
														'REDDEDİLDİ' && (
														<Button
															size="xs"
															variant="danger-outline"
															onClick={() =>
																saveEmployeePhotoStatus(
																	{
																		employeeImageId:
																			item.id,
																		employeeImageStatus:
																			'DENIED',
																	}
																)
															}
															className="inline-flex"
														>
															Reddet
														</Button>
													)}
													<Button
														size="xs"
														variant="primary"
														onClick={() =>
															saveEmployeePhotoStatus(
																{
																	employeeImageId:
																		item.id,
																	employeeImageStatus:
																		'APPROVED',
																}
															)
														}
														className="inline-flex"
													>
														Onayla
													</Button>
												</div>
											</Table.Column>
										</Table.Row>
									)
								)}
						</Table.Body>
					</Table>
				</Page.Content>
			</Page.Body>
			<Page.Footer />

			<Modal
				show={showWaybill}
				loading={false}
				size="xl"
				title="İrsaliye Detayı"
				onClose={() => {
					setShowWaybill(false);
				}}
			>
				<div className="flex flex-col gap-4 p-6">
					<Table className="!table-fixed">
						<Table.Head
							data={['Id', 'İrsaliye', 'Ürün Kategorisi', 'İrsaliye Tarihi', 'Durum', '']}
						/>
						<Table.Body>
							<Table.Row>
								<Table.Column>
									<span className="whitespace-nowrap text-xs font-normal text-slate-400">
										{activeItem.id}
									</span>
								</Table.Column>
								<Table.Column>
									<a href={activeItem.imageUrl} target="_blank" rel="noreferrer">
										<img
											src={activeItem.imageUrl}
											alt=""
											className="aspect-square w-20 rounded-lg object-cover"
										/>
									</a>
								</Table.Column>
								<Table.Column
									className="w-1/3 !whitespace-normal text-xs capitalize text-slate-400"
								>
									<h6 className="mb-1 font-normal">
										Ürün Kategorisi 1
									</h6>
								</Table.Column>
								<Table.Column>
									27.10.2024
								</Table.Column>
								<Table.Column>
									<Badge
										variant={
											{
												ONAYLANDI:
													'success',
												REDDEDİLDİ:
													'danger',
												'ONAY BEKLİYOR':
													'warning',
											}[activeItem.imageStatus]
										}
									>
										{
											{
												ONAYLANDI:
													'Onaylandı',
												REDDEDİLDİ:
													'Reddedildi',
												'ONAY BEKLİYOR':
													'Onay Bekliyor',
											}[activeItem.imageStatus]
										}
									</Badge>
        </Table.Column>
								<Table.Column className="text-right [&_button]:w-20">
									<div className="flex flex-col items-end justify-center gap-2">
										{activeItem.imageStatus !==
										'REDDEDİLDİ' && (
<Button
											size="xs"
											variant="danger-outline"
											className="inline-flex"
											onClick={() => {
												saveEmployeePhotoStatus(
													{
														employeeImageId:
														activeItem.id,
														employeeImageStatus:
															'DENIED',
													}
												);
												setShowWaybill(false);
											}}
>
											Reddet
</Button>
										)}

										<Button
											size="xs"
											variant="primary"
											className="inline-flex"
											onClick={() => {
												saveEmployeePhotoStatus(
													{
														employeeImageId:
														activeItem.id,
														employeeImageStatus:
															'APPROVED',
													}
												);
												setShowWaybill(false);
											}}
										>
											Onayla
										</Button>

									</div>
								</Table.Column>
							</Table.Row>
						</Table.Body>
					</Table>
				</div>
			</Modal>
		</Page>
	);
};

export default EmployeePhotos;
